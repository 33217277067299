<template>
  <div id="programList">
    <v-data-table
      :headers="headers"
      :items="programList.data.data"
      :options.sync="options"
      @update:options="updateOptions"
      :server-items-length="programList.data.meta.total"
      :loading="programList.loading"
      class="elevation-1"
      :footer-props="{
        'items-per-page-options': [5, 10, 25, 50, 100],
      }"
    >
      <template v-slot:header.title="{ header }">
        <span class="font-medium text-base py-4" style="color: #222222">{{
          header.text
        }}</span>
      </template>

      <template v-slot:header.category="{ header }">
        <span class="font-medium text-base py-4" style="color: #222222">{{
          header.text
        }}</span>
      </template>

      <template v-slot:item.title="{ item }">
        <ProgramPreview :program="item" />
      </template>

      <template v-slot:item.category="{ item }">
        <v-chip>{{ item.category.label }}</v-chip>
      </template>

      <template v-slot:item.id="{ item }">
        <router-link :to="`/program/${item.id}`">
          <v-btn outlined>
            <span class="text-sm">Edit</span>
          </v-btn>
        </router-link>

        <v-btn
          outlined
          class="text-xs mx-2"
          @click="selectItem(item, 'duplicate')"
          :loading="
            selectedItem?.id === item.id &&
            selectedItemLoading &&
            pendingAction === 'duplicate'
          "
        >
          <span class="text-sm">Duplicate</span>
        </v-btn>

        <v-btn
          outlined
          class="text-xs"
          @click="selectItem(item, 'delete')"
          :loading="
            selectedItem?.id === item.id &&
            selectedItemLoading &&
            pendingAction === 'delete'
          "
        >
          <span class="text-sm">Delete</span>
        </v-btn>
      </template>
    </v-data-table>

    <ConfirmModal
      :loading.sync="selectedItemLoading"
      title="Duplicate Program"
      message="Are you sure you want to duplicate program?"
      v-model="showDuplicateModal"
      @cancel="showDuplicateModal = false"
      @confirm="handleDuplicateConfirm"
    />

    <ConfirmModal
      :loading.sync="selectedItemLoading"
      title="Delete Program"
      message="This actions is irreversible. <br/> Are you sure you want to delete the program ?"
      v-model="showDeleteModal"
      @cancel="showDeleteModal = false"
      @confirm="handleDeleteConfirm"
    />
  </div>
</template>

<script>
import Api from '@/services/api'
import { mapActions, mapState } from 'vuex'
import ProgramPreview from './ProgramPreview.vue'
import ConfirmModal from '@/components/modals/ConfirmModal'

export default {
  name: 'ProgramList',
  components: {
    ProgramPreview,
    ConfirmModal,
  },
  data() {
    return {
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['title'],
        sortDesc: [false],
      },
      selectedItem: null,
      pendingAction: '',
      selectedItemLoading: false,
      showDuplicateModal: false,
      showDeleteModal: false,
      headers: [
        {
          text: 'Program',
          align: 'start',
          sortable: true,
          value: 'title',
        },
        {
          text: 'Categories',
          align: 'start',
          sortable: true,
          value: 'category',
        },
        {
          text: 'Actions',
          align: 'start',
          sortable: false,
          value: 'id',
        },
      ],
    }
  },
  watch: {
    '$route.query': 'syncOptionsWithRoute',
    options: {
      handler() {
        this.getPrograms()
      },
      deep: true,
    },
  },
  computed: {
    ...mapState({
      programList: (state) => state.program.programList,
    }),
  },
  created() {
    this.syncOptionsWithRoute()
    this.getPrograms()
  },
  methods: {
    ...mapActions({
      getProgramList: 'program/getProgramList',
    }),
    updateOptions(newOptions) {
      this.options = newOptions
      this.updateRoute()
    },
    syncOptionsWithRoute() {
      const query = this.$route.query
      this.options = {
        ...this.options,
        page: query.page ? parseInt(query.page) : 1,
        itemsPerPage: query.itemsPerPage ? parseInt(query.itemsPerPage) : 10,
        sortBy: query.sortBy ? query.sortBy.split(',') : [],
        sortDesc: query.sortDesc
          ? query.sortDesc.split(',').map((v) => v === 'true')
          : [],
      }
    },
    updateRoute() {
      const { page, itemsPerPage, sortBy, sortDesc } = this.options
      this.$router.push({
        query: {
          page: page,
          itemsPerPage: itemsPerPage,
          sortBy: sortBy.join(','),
          sortDesc: sortDesc.join(','),
          search: this.$route.query.search ?? '',
        },
      })
    },
    async getPrograms() {
      let params = {
        page: this.options?.page ?? 1,
        per_page: this.options?.itemsPerPage ?? 10,
        sort: this.options.sortBy.map((sort, idx) =>
          this.options.sortDesc[idx] ? `-${sort}` : sort
        ),
        search: this.$route.query.search ?? '',
      }

      await this.getProgramList(params)
    },
    selectItem(program, action = '') {
      this.selectedItem = program

      if (action === 'duplicate') {
        this.showDuplicateModal = true
      }

      if (action === 'delete') {
        this.showDeleteModal = true
      }
    },
    async handleDuplicateConfirm() {
      this.selectedItemLoading = true
      this.pendingAction = 'duplicate'

      try {
        await Api.post(`programs/${this.selectedItem?.id}/duplicate`)
        this.getPrograms()

        this.$store.commit('setSnackbar', {
          show: true,
          color: 'success',
          text: 'Program successfully duplicated.',
        })
      } catch (error) {
      } finally {
        this.selectedItem = null
        this.selectedItemLoading = false
        this.showDuplicateModal = false
      }
    },
    async handleDeleteConfirm() {
      this.selectedItemLoading = true
      this.pendingAction = 'delete'

      try {
        await Api.delete(`programs/${this.selectedItem?.id}`)
        this.getPrograms()

        this.$store.commit('setSnackbar', {
          show: true,
          color: 'success',
          text: 'Program successfully deleted.',
        })
      } catch (error) {
      } finally {
        this.selectedItem = null
        this.selectedItemLoading = false
        this.showDeleteModal = false
      }
    },
  },
}
</script>
