var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"programList"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.programList.data.data,"options":_vm.options,"server-items-length":_vm.programList.data.meta.total,"loading":_vm.programList.loading,"footer-props":{
      'items-per-page-options': [5, 10, 25, 50, 100],
    }},on:{"update:options":[function($event){_vm.options=$event},_vm.updateOptions]},scopedSlots:_vm._u([{key:"header.title",fn:function({ header }){return [_c('span',{staticClass:"font-medium text-base py-4",staticStyle:{"color":"#222222"}},[_vm._v(_vm._s(header.text))])]}},{key:"header.category",fn:function({ header }){return [_c('span',{staticClass:"font-medium text-base py-4",staticStyle:{"color":"#222222"}},[_vm._v(_vm._s(header.text))])]}},{key:"item.title",fn:function({ item }){return [_c('ProgramPreview',{attrs:{"program":item}})]}},{key:"item.category",fn:function({ item }){return [_c('v-chip',[_vm._v(_vm._s(item.category.label))])]}},{key:"item.id",fn:function({ item }){return [_c('router-link',{attrs:{"to":`/program/${item.id}`}},[_c('v-btn',{attrs:{"outlined":""}},[_c('span',{staticClass:"text-sm"},[_vm._v("Edit")])])],1),_c('v-btn',{staticClass:"text-xs mx-2",attrs:{"outlined":"","loading":_vm.selectedItem?.id === item.id &&
          _vm.selectedItemLoading &&
          _vm.pendingAction === 'duplicate'},on:{"click":function($event){return _vm.selectItem(item, 'duplicate')}}},[_c('span',{staticClass:"text-sm"},[_vm._v("Duplicate")])]),_c('v-btn',{staticClass:"text-xs",attrs:{"outlined":"","loading":_vm.selectedItem?.id === item.id &&
          _vm.selectedItemLoading &&
          _vm.pendingAction === 'delete'},on:{"click":function($event){return _vm.selectItem(item, 'delete')}}},[_c('span',{staticClass:"text-sm"},[_vm._v("Delete")])])]}}])}),_c('ConfirmModal',{attrs:{"loading":_vm.selectedItemLoading,"title":"Duplicate Program","message":"Are you sure you want to duplicate program?"},on:{"update:loading":function($event){_vm.selectedItemLoading=$event},"cancel":function($event){_vm.showDuplicateModal = false},"confirm":_vm.handleDuplicateConfirm},model:{value:(_vm.showDuplicateModal),callback:function ($$v) {_vm.showDuplicateModal=$$v},expression:"showDuplicateModal"}}),_c('ConfirmModal',{attrs:{"loading":_vm.selectedItemLoading,"title":"Delete Program","message":"This actions is irreversible. <br/> Are you sure you want to delete the program ?"},on:{"update:loading":function($event){_vm.selectedItemLoading=$event},"cancel":function($event){_vm.showDeleteModal = false},"confirm":_vm.handleDeleteConfirm},model:{value:(_vm.showDeleteModal),callback:function ($$v) {_vm.showDeleteModal=$$v},expression:"showDeleteModal"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }