<template>
  <div class="preview">
    <div>
      <v-img
        :lazy-src="program.thumb_nail.thumb_url"
        height="56"
        width="80"
        :src="program.thumb_nail.url"
        class="rounded-lg"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-img
              :src="require('@/assets/icons/file-upload-placeholder.svg')"
            />
          </v-row>
        </template>
      </v-img>
    </div>

    <div class="preview__summary">
      <p class="font-medium text-sm mb-0">{{ program.title }}</p>
      <p class="preview__summary__subtitle font-medium text-xs mb-0">
        {{ program.weekly_exercises_count }}
        {{ program.weekly_exercises_count > 1 ? 'WEEKS' : 'WEEK' }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProgramCard',
  props: {
    program: {
      type: Object,
      default: () => {},
    },
  },
}
</script>

<style scoped lang="scss">
.preview {
  display: flex;

  &__summary {
    margin-left: 1rem;
    flex: 1;
    color: #222222;

    &__subtitle {
      opacity: 0.5;
    }
  }
}
</style>
