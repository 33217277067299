<template>
  <v-card class="mx-auto" style="height: 100%" @click="$emit('click')">
    <template slot="progress">
      <v-progress-linear color="deep-purple" height="10" indeterminate />
    </template>

    <v-img
      height="250"
      :src="get(practitioner, 'avatar.url')"
      :lazy-src="get(practitioner, 'avatar.thumb_url')"
      :alt="get(practitioner, 'avatar.name')"
    />

    <v-card-title v-if="practitioner" class="flex-column align-start">
      <h5 class="text title-3 pa-0">
        {{ practitioner.name }}
      </h5>
      <p
        class="text caption pa-0 px-1 pt-1"
        v-html="practitioner.specialisation"
      ></p>
    </v-card-title>
    <v-card-text v-if="practitioner">
      <!-- <h3 class="text title-3 pa-1">
        {{ practitioner.formatted_price }}
      </h3> -->
    </v-card-text>
  </v-card>
</template>

<script>
import get from 'lodash/get'

export default {
  name: 'ExerciseCard',

  props: {
    practitioner: {
      type: Object,
      default: () => {},
    },
  },

  methods: {
    get,
  },
}
</script>

<style lang="scss" scoped>
.title-3,
.caption {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.title-3 {
  -webkit-line-clamp: 1;
}
.caption {
  -webkit-line-clamp: 2;
  min-height: 30px;
}
</style>
