<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <v-toolbar-title class="headline font-weight-bold"
        >Practitioners</v-toolbar-title
      >

      <v-spacer></v-spacer>
      <v-row v-show="showSearch" align="center" style="max-width: 300px">
        <v-text-field
          class="search"
          ref="searchField"
          flat
          hide-details
          solo
          rounded
          clearable
          background-color="grey lighten-3"
          label="Search"
          v-model="filter.search"
          @input="search"
        ></v-text-field>
      </v-row>

      <template>
        <v-btn class="ml-5" icon @click="showSearchField">
          <img src="@/assets/icons/search.svg" alt height="17.7px" />
        </v-btn>
      </template>
    </v-app-bar>

    <div
      v-infinite-scroll="loadMore"
      :infinite-scroll-disabled="!practitionerLoading"
      infinite-scroll-distance="10"
    >
      <v-container>
        <v-row align="stretch" v-if="practitioners.data">
          <v-col
            v-for="practitioner in practitioners.data"
            :key="practitioner.id"
            cols="12"
            md="4"
          >
            <PractitionerCard
              :practitioner="practitioner"
              @click="
                $router.push({
                  name: 'practitioner.item',
                  params: { id: practitioner.id },
                })
              "
            />
          </v-col>
          <v-col
            cols="12"
            align="center"
            justify="center"
            v-if="practitioners.data.length === 0"
          >
            No match found.
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import ControlsMixin from '@/utils/mixins/Controls'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import PractitionerCard from '@/components/practitioner/PractitionerCard'
import infiniteScroll from 'vue-infinite-scroll'
import { mdiMagnify } from '@mdi/js'
import debounce from 'lodash/debounce'

export default {
  name: 'PractitionerCategoriesPage',
  mixins: [ControlsMixin],
  directives: {
    infiniteScroll,
  },
  components: {
    AppBarNavIcon,
    PractitionerCard,
  },
  computed: {
    ...mapState('practitioner', {
      practitioners: (state) => state.practitioners,
      practitionerLoading: (state) => state.practitionerLoading,
    }),
  },
  data() {
    return {
      showSearch: false,
      loading: false,
      filter: {
        search: '',
      },
      icons: {
        search: mdiMagnify,
      },
    }
  },
  mounted() {
    this.getPractitioners()
  },
  methods: {
    ...mapMutations({
      clearPractitioners: 'practitioner/clearPractitioners',
    }),
    ...mapActions({
      getPractitioners: 'practitioner/getPractitioners',
    }),
    loadMore() {
      if (
        this.practitioners?.meta?.current_page <
        this.practitioners?.meta?.last_page
      ) {
        this.getPractitioners({
          loadMore: true,
          page: this.practitioners.meta.current_page + 1,
        })
      }
    },
    search: debounce(function () {
      this.clearPractitioners()
      this.fetchPractitioners()
    }, 600),
    async fetchPractitioners(page = 1) {
      if (this.loading) return
      let params = {
        page,
      }
      if (this.filter.search) {
        params.search = this.filter.search
      }
      this.loading = true
      await this.getPractitioners(params)
      this.loading = false
    },
    async showSearchField() {
      this.showSearch = !this.showSearch
      await this.$nextTick()
      this.$refs.searchField.$refs.input.focus()
    },
  },
}
</script>
