<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <v-toolbar-title>
        <h3 class="headline font-weight-bold">Programs</h3>
        <h4 class="poppins-regular font-normal text-sm">
          Manage your programs in this page
        </h4>
      </v-toolbar-title>
    </v-app-bar>

    <div class="pa-4">
      <div class="d-flex justify-space-between align-center">
        <div>
          <v-btn
            outlined
            color="primary"
            large
            class="mr-4"
            @click="openProgramForm = true"
          >
            <v-icon color="primary">
              {{ icons.mdiPlus }}
            </v-icon>
            <span class="text-sm font-semibold">Program</span>
          </v-btn>

          <v-btn
            outlined
            color="primary"
            large
            @click="openCategoryForm = true"
          >
            <v-icon color="primary">
              {{ icons.mdiPlus }}
            </v-icon>
            <span class="text-sm font-semibold">Category</span>
          </v-btn>
        </div>

        <div style="min-width: 16rem">
          <v-text-field
            class="input__outlined--regular text-sm w-100"
            label="Search"
            outlined
            hide-details
            v-model.trim.lazy="search"
            @input="refreshList"
          />
        </div>
      </div>

      <div class="program-page-content">
        <v-tabs
          v-model="tab"
          style="
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            border-bottom: 0.5px solid rgba(0, 0, 0, 0.25);
          "
        >
          <v-tabs-slider color="primary"></v-tabs-slider>
          <v-tab
            @change="changeTab"
            v-for="tab in ['Programs', 'Categories']"
            :key="`tab-header-${tab}`"
            class="font-medium text-base normal-case"
          >
            {{ tab }}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab" style="border-radius: 8px">
          <v-tab-item key="Programs">
            <program-list />
          </v-tab-item>

          <v-tab-item key="Categories">
            <category-list />
          </v-tab-item>
        </v-tabs-items>
      </div>
    </div>

    <ProgramFormModal v-model="openProgramForm" />
    <CategoryFormModal v-model="openCategoryForm" />
  </div>
</template>
<script>
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import { mdiPlus } from '@mdi/js'
import ProgramList from '@/components/program/ProgramList.vue'
import CategoryList from '@/components/program/category/CategoryList.vue'
import ProgramFormModal from '@/components/program/ProgramFormModal.vue'
import CategoryFormModal from '@/components/program/category/CategoryFormModal.vue'
import { mapActions, mapState } from 'vuex'
import debounce from 'lodash/debounce'

export default {
  name: 'ProgramIndex',
  components: {
    AppBarNavIcon,
    ProgramList,
    ProgramFormModal,
    CategoryList,
    CategoryFormModal,
  },
  data() {
    return {
      icons: {
        mdiPlus,
      },
      tab: 'Programs',
      openProgramForm: false,
      openCategoryForm: false,
      search: '',
    }
  },
  computed: {
    ...mapState({
      programList: (state) => state.program.programList,
      programCategoryList: (state) => state.programCategory.categoryList,
    }),
  },
  created() {
    this.search = this.$route.query.search || ''
  },
  methods: {
    ...mapActions({
      getProgramList: 'program/getProgramList',
      getProgramCategoryList: 'programCategory/getProgramCategoryList',
    }),
    changeTab() {
      this.search = ''
      this.$router.replace({ query: null })
      this.refreshList()
    },
    refreshList: debounce(async function (search) {
      if (this.tab === 0) {
        const { params } = this.programList

        await this.getProgramList({ ...params, search })
      }

      if (this.tab === 1) {
        const { params } = this.programCategoryList

        await this.getProgramCategoryList({ ...params, search })
      }

      this.$router.push({
        query: {
          ...this.$route.query,
          search: search,
        },
      })
    }, 500),
  },
}
</script>

<style>
.program-page-content {
  margin-top: 2rem;
  border: 0.5px solid rgba(0, 0, 0, 0.25);
  border-radius: 0.5rem;
}
</style>
