<script>
import axios from 'axios'
import router from '@/routes'
import { mapActions, mapState } from 'vuex'
import ContentEditor from '@/components/ContentEditor'
import Form from '@/utils/form'
import { mdiClose } from '@mdi/js'
import Api from '@/services/api'
import FileUploader from '../base/FileUploader.vue'
import ThumbnailUploader from '../base/ThumbnailUploader.vue'

export default {
  name: 'ProgramFormModal',
  components: {
    FileUploader,
    ContentEditor,
    ThumbnailUploader,
  },
  props: {
    value: Boolean,
  },
  computed: {
    ...mapState({
      programCategories: (state) => state.programCategory.categories,
    }),
  },
  data() {
    return {
      icons: {
        mdiClose,
      },
      submitting: false,
      form: new Form({
        category_id: null,
        title: null,
        content: null,
        video_thumb_nail_id: null,
        thumb_nail_id: null,
        is_free: 0,
        video: null,
        thumbnail: null,
      }),
    }
  },
  mounted() {
    this.getProgramCategories()
  },
  methods: {
    ...mapActions({
      getProgramCategories: 'programCategory/getProgramCategories',
    }),
    async uploadFile(file) {
      const response = await Api.post('/s3-upload-signed-url/generate', {
        file_name: file.name,
        mime_type: file.type,
        size: file.size,
      })

      const { id, url } = response.data.data

      await axios.put(url, file, {
        headers: { 'Content-Type': file.type },
      })

      return id
    },
    async submit() {
      this.submitting = true

      try {
        if (this.form.video) {
          this.form.video_thumb_nail_id = await this.uploadFile(this.form.video)
        }

        if (this.form.thumbnail) {
          this.form.thumb_nail_id = await this.uploadFile(this.form.thumbnail)
        }

        const response = await Api.post('/admin/programs', this.form)

        this.form.$reset()
        this.$emit('input', false)

        const { id } = response.data.data

        router.push(`/program/${id}`)

        this.$store.commit('setSnackbar', {
          show: true,
          color: 'success',
          text: 'Program successfully created.',
        })
      } catch (e) {
        if (e.response?.data?.errors) {
          this.form.$setErrors(e.response?.data?.errors)
        }
      } finally {
        this.submitting = false
      }
    },
    cancelForm() {
      this.form.$reset()
      this.$emit('input', false)
    },
  },
}
</script>

<template>
  <v-dialog
    v-bind:value="value"
    width="1028"
    v-on:input="(newValue) => $emit('input', newValue)"
    scrollable
  >
    <v-card>
      <v-card-title class="font-semibold text-2xl">
        Create a new program
      </v-card-title>

      <v-card-text class="mt-5" style="max-height: 60vh">
        <v-row>
          <v-col cols="3">
            <thumbnail-uploader
              v-model="form.thumbnail"
              :errors="form.$getError('thumb_nail_id')"
              @input="form.$clearError('thumb_nail_id')"
            />
          </v-col>

          <v-col cols="9">
            <v-row>
              <v-col>
                <v-text-field
                  v-model.trim="form.title"
                  :error-messages="form.$getError('title')"
                  class="input__outlined--regular"
                  label="Program Title"
                  outlined
                  @input="form.$clearError('title')"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="9">
                <v-select
                  v-model="form.category_id"
                  :error-messages="form.$getError('category_id')"
                  :items="programCategories"
                  class="input__outlined--regular"
                  item-text="label"
                  item-value="id"
                  label="Category"
                  outlined
                  @change="form.$clearError('category_id')"
                />
              </v-col>
              <v-col cols="3">
                <v-switch
                  class="mt-3"
                  v-model="form.is_free"
                  label="Free"
                  inset
                  hide-details
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <file-uploader
              v-model="form.video"
              label="Introduction Video"
              accept="video/mp4,video/x-m4v,video/*"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <div>
              <p class="text-tertiary text-base">Description</p>

              <ContentEditor
                v-model="form.content"
                :errors="form.$getError('content')"
                class="content-editor"
                @input="form.$clearError('content')"
              />
            </div>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-col>
          <v-btn
            :disabled="submitting"
            :loading="submitting"
            block
            color="primary"
            large
            @click="submit"
          >
            <span class="text-sm">Create Program</span>
          </v-btn>
        </v-col>

        <v-col>
          <v-btn
            :disabled="submitting"
            block
            class="text-sm"
            color="primary"
            large
            outlined
            @click="cancelForm"
          >
            <span class="text-sm">Cancel</span>
          </v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style lang="scss" scoped>
.video-preview,
.video-upload {
  height: 100%;
  border-radius: 8px;
}

.video-upload {
  border: 1px dashed #3b55ce;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-preview {
  background: black;

  &__header {
    position: absolute;
    right: 4px;
    top: 4px;
  }
}

#video-preview__content {
  height: 100%;
  width: 100%;
}

.thumbnail-preview {
  height: 4rem;
  width: 4rem;
  border: 1px dashed #3b55ce;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
}
</style>
