<script>
import Form from '@/utils/form'
import { mdiClose } from '@mdi/js'
import Api from '@/services/api'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'CategoryFormModal',
  props: {
    value: Boolean,
  },
  data() {
    return {
      icons: {
        mdiClose,
      },
      submitting: false,
      form: new Form({
        id: null,
        label: null,
        type: 'program',
      }),
    }
  },
  computed: {
    ...mapState({
      programCategoryList: (state) => state.programCategory.categoryList,
    }),
  },
  methods: {
    ...mapActions({
      getProgramCategoryList: 'programCategory/getProgramCategoryList',
    }),
    setFormData(formData) {
      this.form.id = formData.id
      this.form.label = formData.label
    },
    async submit() {
      this.submitting = true

      try {
        this.form.id
          ? await Api.put(`program/categories/${this.form.id}`, this.form)
          : await Api.post(`program/categories`, this.form)

        this.form.$reset()
        this.$emit('input', false)

        const { params } = this.programCategoryList
        await this.getProgramCategoryList(params)

        this.$store.commit('setSnackbar', {
          show: true,
          color: 'success',
          text: `Category successfully ${
            this.form.id ? 'updated' : 'created'
          }.`,
        })
      } catch (e) {
        if (e.response?.data?.errors) {
          this.form.$setErrors(e.response?.data?.errors)
        }
      } finally {
        this.submitting = false
      }
    },
    cancelForm() {
      this.form.$reset()
      this.$emit('input', false)
    },
  },
}
</script>

<template>
  <v-dialog
    v-bind:value="value"
    width="400"
    v-on:input="(newValue) => $emit('input', newValue)"
  >
    <v-card>
      <v-card-title class="font-semibold text-2xl">
        {{ form.id ? 'Update' : 'Add new' }} Category
      </v-card-title>

      <v-card-text class="mt-5">
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model.trim="form.label"
              :error-messages="form.$getError('label')"
              class="input__outlined--regular"
              label="Label"
              outlined
              @input="form.$clearError('label')"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-col>
          <v-btn
            :disabled="submitting"
            :loading="submitting"
            block
            color="primary"
            large
            @click="submit"
          >
            <span class="text-sm"
              >{{ form.id ? 'Update' : 'Create' }} Category</span
            >
          </v-btn>
        </v-col>

        <v-col>
          <v-btn
            :disabled="submitting"
            block
            class="text-sm"
            color="primary"
            large
            outlined
            @click="cancelForm"
          >
            <span class="text-sm">Cancel</span>
          </v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
