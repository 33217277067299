<template>
  <div id="programCategoryList">
    <v-data-table
      :headers="headers"
      :items="programCategoryList.data.data"
      :options.sync="options"
      :server-items-length="programCategoryList.data.meta.total"
      :loading="programCategoryList.loading"
      class="elevation-1"
      :footer-props="{
        'items-per-page-options': [5, 10, 25, 50, 100],
      }"
    >
      <template v-slot:header.category="{ header }">
        <span class="font-medium text-base py-4" style="color: #222222">{{
          header.text
        }}</span>
      </template>

      <template v-slot:item.id="{ item }">
        <v-btn outlined class="mx-1">
          <span class="text-sm" @click="selectItem(item, 'edit')">Edit</span>
        </v-btn>

        <v-btn
          outlined
          class="text-xs mx-1"
          @click="selectItem(item, 'delete')"
          :loading="
            selectedItem?.id === item.id &&
            selectedItemLoading &&
            pendingAction == 'delete'
          "
        >
          <span class="text-sm">Delete</span>
        </v-btn>
      </template>
    </v-data-table>

    <ConfirmModal
      :loading.sync="selectedItemLoading"
      title="Delete category"
      message="Do you want to delete this category?"
      v-model="showDeleteModal"
      @cancel="showDeleteModal = false"
      @confirm="handleDeleteConfirm"
    />

    <CategoryFormModal ref="form" v-model="openForm" />
  </div>
</template>

<script>
import Api from '@/services/api'
import { mapActions, mapState } from 'vuex'
import ConfirmModal from '@/components/modals/ConfirmModal'
import CategoryFormModal from './CategoryFormModal.vue'

export default {
  name: 'CategoryList',
  components: {
    ConfirmModal,
    CategoryFormModal,
  },
  data() {
    return {
      options: {},
      openForm: false,
      selectedItem: null,
      pendingAction: '',
      selectedItemLoading: false,
      showDeleteModal: false,
      headers: [
        {
          text: 'Category',
          align: 'start',
          sortable: true,
          value: 'label',
        },
        {
          text: 'Actions',
          align: 'start',
          sortable: false,
          value: 'id',
        },
      ],
    }
  },
  watch: {
    options: {
      handler() {
        this.getProgramCategories()
      },
      deep: true,
    },
  },
  computed: {
    ...mapState({
      programCategoryList: (state) => state.programCategory.categoryList,
    }),
  },
  mounted() {
    this.getProgramCategories()
  },
  methods: {
    ...mapActions({
      getProgramCategoryList: 'programCategory/getProgramCategoryList',
    }),
    async getProgramCategories() {
      let params = {
        page: this.options?.page ?? 1,
        per_page: this.options?.itemsPerPage ?? 10,
        sort: this.options.sortBy.map((sort, idx) =>
          this.options.sortDesc[idx] ? `-${sort}` : sort
        ),
      }

      await this.getProgramCategoryList(params)
    },
    selectItem(program, action = '') {
      this.selectedItem = program

      if (action === 'delete') {
        this.showDeleteModal = true
      }

      if (action === 'edit') {
        this.$refs.form.setFormData(program)
        this.openForm = true
      }
    },
    async handleDeleteConfirm() {
      this.selectedItemLoading = true
      this.pendingAction = 'delete'

      try {
        await Api.delete(`program/categories/${this.selectedItem?.id}`)
        this.getProgramCategories()

        this.$store.commit('setSnackbar', {
          show: true,
          color: 'success',
          text: 'Category successfully deleted.',
        })
      } catch ({ response: { data } }) {
        if (data.message) {
          this.$store.commit('setSnackbar', {
            show: true,
            color: 'error',
            text: data.message,
          })
        }
      } finally {
        this.selectedItem = null
        this.selectedItemLoading = false
        this.showDeleteModal = false
      }
    },
  },
}
</script>
